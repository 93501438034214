*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.m-auto {
  margin: auto;
}

.m-2 {
  margin: .5rem;
}

.m-24 {
  margin: 6rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-12 {
  margin-top: 3rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.w-96 {
  width: 24rem;
}

.w-24 {
  width: 6rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-6\/12 {
  width: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) );
}

.p-10 {
  padding: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.text-center {
  text-align: center;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.font-bold {
  font-weight: 700;
}

.leading-tight {
  line-height: 1.25;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@font-face {
  font-family: Franxfurter;
  src: url("Franxfurter.4e54a98c.woff");
}

@font-face {
  font-family: Watasuki Tech Sans;
  src: url("WatatsukiTechSans.c37a810c.woff");
}

body {
  color: #fff;
  background-color: #5aa1e9;
  font-family: Watasuki Tech Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1, h2, h3, h4, h5, button.rounded-full, a.rounded-full {
  font-family: Franxfurter, sans-serif;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity) );
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.disabled\:cursor-no-drop:disabled {
  cursor: no-drop;
}

.disabled\:bg-gray-500:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

@media (min-width: 1024px) {
  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.iekbcc0 {
  box-sizing: border-box;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  line-height: normal;
}

[data-js-focus-visible] .iekbcc0:focus:not([data-focus-visible-added]) {
  outline: none;
}

.iekbcc1 {
  list-style: none;
}

.iekbcc2 {
  quotes: none;
}

.iekbcc2:before, .iekbcc2:after {
  content: "";
}

.iekbcc3 {
  border-collapse: collapse;
  border-spacing: 0;
}

.iekbcc4 {
  appearance: none;
}

.iekbcc5 {
  outline: none;
}

.iekbcc5::-moz-placeholder {
  opacity: 1;
}

.iekbcc5:-ms-input-placeholder {
  opacity: 1;
}

.iekbcc5::placeholder {
  opacity: 1;
}

.iekbcc6 {
  color: inherit;
  background-color: #0000;
}

.iekbcc7:disabled {
  opacity: 1;
}

.iekbcc7::-ms-expand {
  display: none;
}

.iekbcc8::-ms-clear {
  display: none;
}

.iekbcc8::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.iekbcc9 {
  cursor: pointer;
  text-align: left;
  background: none;
}

.iekbcca {
  color: inherit;
  text-decoration: none;
}

.ju367v0, .ju367v1:hover, .ju367v2:active {
  transform: scale(1.025);
}

.ju367v3, .ju367v4:hover, .ju367v5:active {
  transform: scale(1.1);
}

.ju367v6, .ju367v7:hover, .ju367v8:active {
  transform: scale(.95);
}

.ju367v9, .ju367va:hover, .ju367vb:active {
  transform: scale(.9);
}

.ju367vc, .ju367vd:hover, .ju367ve:active {
  transition: all .125s;
}

.ju367vf {
  align-items: flex-start;
}

.ju367vh {
  align-items: flex-end;
}

.ju367vj {
  align-items: center;
}

.ju367vl {
  display: none;
}

.ju367vn {
  display: block;
}

.ju367vp {
  display: flex;
}

.ju367vr {
  align-self: flex-start;
}

.ju367vs {
  align-self: flex-end;
}

.ju367vt {
  align-self: center;
}

.ju367vu {
  background-size: cover;
}

.ju367vv {
  border-radius: 1px;
}

.ju367vw {
  border-radius: 6px;
}

.ju367vx {
  border-radius: 10px;
}

.ju367vy {
  border-radius: 13px;
}

.ju367vz {
  border-radius: var(--rk-radii-actionButton);
}

.ju367v10 {
  border-radius: var(--rk-radii-connectButton);
}

.ju367v11 {
  border-radius: var(--rk-radii-menuButton);
}

.ju367v12 {
  border-radius: var(--rk-radii-modal);
}

.ju367v13 {
  border-radius: var(--rk-radii-modalMobile);
}

.ju367v14 {
  border-radius: 25%;
}

.ju367v15 {
  border-radius: 9999px;
}

.ju367v16 {
  border-style: solid;
}

.ju367v17 {
  border-width: 0;
}

.ju367v18 {
  border-width: 1px;
}

.ju367v19 {
  border-width: 2px;
}

.ju367v1a {
  border-width: 4px;
}

.ju367v1b {
  cursor: pointer;
}

.ju367v1c {
  flex-direction: row;
}

.ju367v1d {
  flex-direction: column;
}

.ju367v1e {
  font-family: var(--rk-fonts-body);
}

.ju367v1f {
  font-size: 12px;
}

.ju367v1g {
  font-size: 13px;
}

.ju367v1h {
  font-size: 14px;
}

.ju367v1i {
  font-size: 16px;
}

.ju367v1j {
  font-size: 18px;
}

.ju367v1k {
  font-size: 20px;
}

.ju367v1l {
  font-size: 23px;
}

.ju367v1m {
  font-weight: 400;
}

.ju367v1n {
  font-weight: 500;
}

.ju367v1o {
  font-weight: 600;
}

.ju367v1p {
  font-weight: 700;
}

.ju367v1q {
  font-weight: 800;
}

.ju367v1r {
  gap: 0;
}

.ju367v1s {
  gap: 1px;
}

.ju367v1t {
  gap: 2px;
}

.ju367v1u {
  gap: 4px;
}

.ju367v1v {
  gap: 5px;
}

.ju367v1w {
  gap: 6px;
}

.ju367v1x {
  gap: 8px;
}

.ju367v1y {
  gap: 10px;
}

.ju367v1z {
  gap: 12px;
}

.ju367v20 {
  gap: 14px;
}

.ju367v21 {
  gap: 16px;
}

.ju367v22 {
  gap: 18px;
}

.ju367v23 {
  gap: 20px;
}

.ju367v24 {
  gap: 24px;
}

.ju367v25 {
  gap: 28px;
}

.ju367v26 {
  gap: 32px;
}

.ju367v27 {
  gap: 36px;
}

.ju367v28 {
  gap: 44px;
}

.ju367v29 {
  gap: 64px;
}

.ju367v2a {
  gap: -1px;
}

.ju367v2b {
  height: 1px;
}

.ju367v2c {
  height: 2px;
}

.ju367v2d {
  height: 4px;
}

.ju367v2e {
  height: 8px;
}

.ju367v2f {
  height: 12px;
}

.ju367v2g {
  height: 20px;
}

.ju367v2h {
  height: 24px;
}

.ju367v2i {
  height: 28px;
}

.ju367v2j {
  height: 30px;
}

.ju367v2k {
  height: 32px;
}

.ju367v2l {
  height: 34px;
}

.ju367v2m {
  height: 36px;
}

.ju367v2n {
  height: 40px;
}

.ju367v2o {
  height: 48px;
}

.ju367v2p {
  height: 54px;
}

.ju367v2q {
  height: 60px;
}

.ju367v2r {
  height: 100%;
}

.ju367v2s {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.ju367v2t {
  justify-content: flex-start;
}

.ju367v2u {
  justify-content: flex-end;
}

.ju367v2v {
  justify-content: center;
}

.ju367v2w {
  justify-content: space-between;
}

.ju367v2x {
  text-align: left;
}

.ju367v2y {
  text-align: center;
}

.ju367v2z {
  text-align: inherit;
}

.ju367v30 {
  margin-bottom: 0;
}

.ju367v31 {
  margin-bottom: 1px;
}

.ju367v32 {
  margin-bottom: 2px;
}

.ju367v33 {
  margin-bottom: 4px;
}

.ju367v34 {
  margin-bottom: 5px;
}

.ju367v35 {
  margin-bottom: 6px;
}

.ju367v36 {
  margin-bottom: 8px;
}

.ju367v37 {
  margin-bottom: 10px;
}

.ju367v38 {
  margin-bottom: 12px;
}

.ju367v39 {
  margin-bottom: 14px;
}

.ju367v3a {
  margin-bottom: 16px;
}

.ju367v3b {
  margin-bottom: 18px;
}

.ju367v3c {
  margin-bottom: 20px;
}

.ju367v3d {
  margin-bottom: 24px;
}

.ju367v3e {
  margin-bottom: 28px;
}

.ju367v3f {
  margin-bottom: 32px;
}

.ju367v3g {
  margin-bottom: 36px;
}

.ju367v3h {
  margin-bottom: 44px;
}

.ju367v3i {
  margin-bottom: 64px;
}

.ju367v3j {
  margin-bottom: -1px;
}

.ju367v3k {
  margin-left: 0;
}

.ju367v3l {
  margin-left: 1px;
}

.ju367v3m {
  margin-left: 2px;
}

.ju367v3n {
  margin-left: 4px;
}

.ju367v3o {
  margin-left: 5px;
}

.ju367v3p {
  margin-left: 6px;
}

.ju367v3q {
  margin-left: 8px;
}

.ju367v3r {
  margin-left: 10px;
}

.ju367v3s {
  margin-left: 12px;
}

.ju367v3t {
  margin-left: 14px;
}

.ju367v3u {
  margin-left: 16px;
}

.ju367v3v {
  margin-left: 18px;
}

.ju367v3w {
  margin-left: 20px;
}

.ju367v3x {
  margin-left: 24px;
}

.ju367v3y {
  margin-left: 28px;
}

.ju367v3z {
  margin-left: 32px;
}

.ju367v40 {
  margin-left: 36px;
}

.ju367v41 {
  margin-left: 44px;
}

.ju367v42 {
  margin-left: 64px;
}

.ju367v43 {
  margin-left: -1px;
}

.ju367v44 {
  margin-right: 0;
}

.ju367v45 {
  margin-right: 1px;
}

.ju367v46 {
  margin-right: 2px;
}

.ju367v47 {
  margin-right: 4px;
}

.ju367v48 {
  margin-right: 5px;
}

.ju367v49 {
  margin-right: 6px;
}

.ju367v4a {
  margin-right: 8px;
}

.ju367v4b {
  margin-right: 10px;
}

.ju367v4c {
  margin-right: 12px;
}

.ju367v4d {
  margin-right: 14px;
}

.ju367v4e {
  margin-right: 16px;
}

.ju367v4f {
  margin-right: 18px;
}

.ju367v4g {
  margin-right: 20px;
}

.ju367v4h {
  margin-right: 24px;
}

.ju367v4i {
  margin-right: 28px;
}

.ju367v4j {
  margin-right: 32px;
}

.ju367v4k {
  margin-right: 36px;
}

.ju367v4l {
  margin-right: 44px;
}

.ju367v4m {
  margin-right: 64px;
}

.ju367v4n {
  margin-right: -1px;
}

.ju367v4o {
  margin-top: 0;
}

.ju367v4p {
  margin-top: 1px;
}

.ju367v4q {
  margin-top: 2px;
}

.ju367v4r {
  margin-top: 4px;
}

.ju367v4s {
  margin-top: 5px;
}

.ju367v4t {
  margin-top: 6px;
}

.ju367v4u {
  margin-top: 8px;
}

.ju367v4v {
  margin-top: 10px;
}

.ju367v4w {
  margin-top: 12px;
}

.ju367v4x {
  margin-top: 14px;
}

.ju367v4y {
  margin-top: 16px;
}

.ju367v4z {
  margin-top: 18px;
}

.ju367v50 {
  margin-top: 20px;
}

.ju367v51 {
  margin-top: 24px;
}

.ju367v52 {
  margin-top: 28px;
}

.ju367v53 {
  margin-top: 32px;
}

.ju367v54 {
  margin-top: 36px;
}

.ju367v55 {
  margin-top: 44px;
}

.ju367v56 {
  margin-top: 64px;
}

.ju367v57 {
  margin-top: -1px;
}

.ju367v58 {
  max-width: 1px;
}

.ju367v59 {
  max-width: 2px;
}

.ju367v5a {
  max-width: 4px;
}

.ju367v5b {
  max-width: 8px;
}

.ju367v5c {
  max-width: 12px;
}

.ju367v5d {
  max-width: 20px;
}

.ju367v5e {
  max-width: 24px;
}

.ju367v5f {
  max-width: 28px;
}

.ju367v5g {
  max-width: 30px;
}

.ju367v5h {
  max-width: 32px;
}

.ju367v5i {
  max-width: 34px;
}

.ju367v5j {
  max-width: 36px;
}

.ju367v5k {
  max-width: 40px;
}

.ju367v5l {
  max-width: 48px;
}

.ju367v5m {
  max-width: 54px;
}

.ju367v5n {
  max-width: 60px;
}

.ju367v5o {
  max-width: 100%;
}

.ju367v5p {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.ju367v5q {
  min-width: 1px;
}

.ju367v5r {
  min-width: 2px;
}

.ju367v5s {
  min-width: 4px;
}

.ju367v5t {
  min-width: 8px;
}

.ju367v5u {
  min-width: 12px;
}

.ju367v5v {
  min-width: 20px;
}

.ju367v5w {
  min-width: 24px;
}

.ju367v5x {
  min-width: 28px;
}

.ju367v5y {
  min-width: 30px;
}

.ju367v5z {
  min-width: 32px;
}

.ju367v60 {
  min-width: 34px;
}

.ju367v61 {
  min-width: 36px;
}

.ju367v62 {
  min-width: 40px;
}

.ju367v63 {
  min-width: 48px;
}

.ju367v64 {
  min-width: 54px;
}

.ju367v65 {
  min-width: 60px;
}

.ju367v66 {
  min-width: 100%;
}

.ju367v67 {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.ju367v68 {
  overflow: hidden;
}

.ju367v69 {
  padding-bottom: 0;
}

.ju367v6a {
  padding-bottom: 1px;
}

.ju367v6b {
  padding-bottom: 2px;
}

.ju367v6c {
  padding-bottom: 4px;
}

.ju367v6d {
  padding-bottom: 5px;
}

.ju367v6e {
  padding-bottom: 6px;
}

.ju367v6f {
  padding-bottom: 8px;
}

.ju367v6g {
  padding-bottom: 10px;
}

.ju367v6h {
  padding-bottom: 12px;
}

.ju367v6i {
  padding-bottom: 14px;
}

.ju367v6j {
  padding-bottom: 16px;
}

.ju367v6k {
  padding-bottom: 18px;
}

.ju367v6l {
  padding-bottom: 20px;
}

.ju367v6m {
  padding-bottom: 24px;
}

.ju367v6n {
  padding-bottom: 28px;
}

.ju367v6o {
  padding-bottom: 32px;
}

.ju367v6p {
  padding-bottom: 36px;
}

.ju367v6q {
  padding-bottom: 44px;
}

.ju367v6r {
  padding-bottom: 64px;
}

.ju367v6s {
  padding-bottom: -1px;
}

.ju367v6t {
  padding-left: 0;
}

.ju367v6u {
  padding-left: 1px;
}

.ju367v6v {
  padding-left: 2px;
}

.ju367v6w {
  padding-left: 4px;
}

.ju367v6x {
  padding-left: 5px;
}

.ju367v6y {
  padding-left: 6px;
}

.ju367v6z {
  padding-left: 8px;
}

.ju367v70 {
  padding-left: 10px;
}

.ju367v71 {
  padding-left: 12px;
}

.ju367v72 {
  padding-left: 14px;
}

.ju367v73 {
  padding-left: 16px;
}

.ju367v74 {
  padding-left: 18px;
}

.ju367v75 {
  padding-left: 20px;
}

.ju367v76 {
  padding-left: 24px;
}

.ju367v77 {
  padding-left: 28px;
}

.ju367v78 {
  padding-left: 32px;
}

.ju367v79 {
  padding-left: 36px;
}

.ju367v7a {
  padding-left: 44px;
}

.ju367v7b {
  padding-left: 64px;
}

.ju367v7c {
  padding-left: -1px;
}

.ju367v7d {
  padding-right: 0;
}

.ju367v7e {
  padding-right: 1px;
}

.ju367v7f {
  padding-right: 2px;
}

.ju367v7g {
  padding-right: 4px;
}

.ju367v7h {
  padding-right: 5px;
}

.ju367v7i {
  padding-right: 6px;
}

.ju367v7j {
  padding-right: 8px;
}

.ju367v7k {
  padding-right: 10px;
}

.ju367v7l {
  padding-right: 12px;
}

.ju367v7m {
  padding-right: 14px;
}

.ju367v7n {
  padding-right: 16px;
}

.ju367v7o {
  padding-right: 18px;
}

.ju367v7p {
  padding-right: 20px;
}

.ju367v7q {
  padding-right: 24px;
}

.ju367v7r {
  padding-right: 28px;
}

.ju367v7s {
  padding-right: 32px;
}

.ju367v7t {
  padding-right: 36px;
}

.ju367v7u {
  padding-right: 44px;
}

.ju367v7v {
  padding-right: 64px;
}

.ju367v7w {
  padding-right: -1px;
}

.ju367v7x {
  padding-top: 0;
}

.ju367v7y {
  padding-top: 1px;
}

.ju367v7z {
  padding-top: 2px;
}

.ju367v80 {
  padding-top: 4px;
}

.ju367v81 {
  padding-top: 5px;
}

.ju367v82 {
  padding-top: 6px;
}

.ju367v83 {
  padding-top: 8px;
}

.ju367v84 {
  padding-top: 10px;
}

.ju367v85 {
  padding-top: 12px;
}

.ju367v86 {
  padding-top: 14px;
}

.ju367v87 {
  padding-top: 16px;
}

.ju367v88 {
  padding-top: 18px;
}

.ju367v89 {
  padding-top: 20px;
}

.ju367v8a {
  padding-top: 24px;
}

.ju367v8b {
  padding-top: 28px;
}

.ju367v8c {
  padding-top: 32px;
}

.ju367v8d {
  padding-top: 36px;
}

.ju367v8e {
  padding-top: 44px;
}

.ju367v8f {
  padding-top: 64px;
}

.ju367v8g {
  padding-top: -1px;
}

.ju367v8h {
  position: absolute;
}

.ju367v8i {
  position: fixed;
}

.ju367v8j {
  position: relative;
}

.ju367v8k {
  right: 0;
}

.ju367v8l {
  -webkit-user-select: none;
  user-select: none;
}

.ju367v8m {
  width: 1px;
}

.ju367v8n {
  width: 2px;
}

.ju367v8o {
  width: 4px;
}

.ju367v8p {
  width: 8px;
}

.ju367v8q {
  width: 12px;
}

.ju367v8r {
  width: 20px;
}

.ju367v8s {
  width: 24px;
}

.ju367v8t {
  width: 28px;
}

.ju367v8u {
  width: 30px;
}

.ju367v8v {
  width: 32px;
}

.ju367v8w {
  width: 34px;
}

.ju367v8x {
  width: 36px;
}

.ju367v8y {
  width: 40px;
}

.ju367v8z {
  width: 48px;
}

.ju367v90 {
  width: 54px;
}

.ju367v91 {
  width: 60px;
}

.ju367v92 {
  width: 100%;
}

.ju367v93 {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.ju367v94, .ju367v95:hover, .ju367v96:active {
  background: var(--rk-colors-accentColor);
}

.ju367v97, .ju367v98:hover, .ju367v99:active {
  background: var(--rk-colors-accentColorForeground);
}

.ju367v9a, .ju367v9b:hover, .ju367v9c:active {
  background: var(--rk-colors-actionButtonBorder);
}

.ju367v9d, .ju367v9e:hover, .ju367v9f:active {
  background: var(--rk-colors-actionButtonBorderMobile);
}

.ju367v9g, .ju367v9h:hover, .ju367v9i:active {
  background: var(--rk-colors-actionButtonSecondaryBackground);
}

.ju367v9j, .ju367v9k:hover, .ju367v9l:active {
  background: var(--rk-colors-closeButton);
}

.ju367v9m, .ju367v9n:hover, .ju367v9o:active {
  background: var(--rk-colors-closeButtonBackground);
}

.ju367v9p, .ju367v9q:hover, .ju367v9r:active {
  background: var(--rk-colors-connectButtonBackground);
}

.ju367v9s, .ju367v9t:hover, .ju367v9u:active {
  background: var(--rk-colors-connectButtonBackgroundError);
}

.ju367v9v, .ju367v9w:hover, .ju367v9x:active {
  background: var(--rk-colors-connectButtonInnerBackground);
}

.ju367v9y, .ju367v9z:hover, .ju367va0:active {
  background: var(--rk-colors-connectButtonText);
}

.ju367va1, .ju367va2:hover, .ju367va3:active {
  background: var(--rk-colors-connectButtonTextError);
}

.ju367va4, .ju367va5:hover, .ju367va6:active {
  background: var(--rk-colors-connectionIndicator);
}

.ju367va7, .ju367va8:hover, .ju367va9:active {
  background: var(--rk-colors-error);
}

.ju367vaa, .ju367vab:hover, .ju367vac:active {
  background: var(--rk-colors-generalBorder);
}

.ju367vad, .ju367vae:hover, .ju367vaf:active {
  background: var(--rk-colors-generalBorderDim);
}

.ju367vag, .ju367vah:hover, .ju367vai:active {
  background: var(--rk-colors-menuItemBackground);
}

.ju367vaj, .ju367vak:hover, .ju367val:active {
  background: var(--rk-colors-modalBackdrop);
}

.ju367vam, .ju367van:hover, .ju367vao:active {
  background: var(--rk-colors-modalBackground);
}

.ju367vap, .ju367vaq:hover, .ju367var:active {
  background: var(--rk-colors-modalBorder);
}

.ju367vas, .ju367vat:hover, .ju367vau:active {
  background: var(--rk-colors-modalText);
}

.ju367vav, .ju367vaw:hover, .ju367vax:active {
  background: var(--rk-colors-modalTextDim);
}

.ju367vay, .ju367vaz:hover, .ju367vb0:active {
  background: var(--rk-colors-modalTextSecondary);
}

.ju367vb1, .ju367vb2:hover, .ju367vb3:active {
  background: var(--rk-colors-profileAction);
}

.ju367vb4, .ju367vb5:hover, .ju367vb6:active {
  background: var(--rk-colors-profileActionHover);
}

.ju367vb7, .ju367vb8:hover, .ju367vb9:active {
  background: var(--rk-colors-profileForeground);
}

.ju367vba, .ju367vbb:hover, .ju367vbc:active {
  background: var(--rk-colors-selectedOptionBorder);
}

.ju367vbd, .ju367vbe:hover, .ju367vbf:active {
  background: var(--rk-colors-standby);
}

.ju367vbg, .ju367vbh:hover, .ju367vbi:active {
  border-color: var(--rk-colors-accentColor);
}

.ju367vbj, .ju367vbk:hover, .ju367vbl:active {
  border-color: var(--rk-colors-accentColorForeground);
}

.ju367vbm, .ju367vbn:hover, .ju367vbo:active {
  border-color: var(--rk-colors-actionButtonBorder);
}

.ju367vbp, .ju367vbq:hover, .ju367vbr:active {
  border-color: var(--rk-colors-actionButtonBorderMobile);
}

.ju367vbs, .ju367vbt:hover, .ju367vbu:active {
  border-color: var(--rk-colors-actionButtonSecondaryBackground);
}

.ju367vbv, .ju367vbw:hover, .ju367vbx:active {
  border-color: var(--rk-colors-closeButton);
}

.ju367vby, .ju367vbz:hover, .ju367vc0:active {
  border-color: var(--rk-colors-closeButtonBackground);
}

.ju367vc1, .ju367vc2:hover, .ju367vc3:active {
  border-color: var(--rk-colors-connectButtonBackground);
}

.ju367vc4, .ju367vc5:hover, .ju367vc6:active {
  border-color: var(--rk-colors-connectButtonBackgroundError);
}

.ju367vc7, .ju367vc8:hover, .ju367vc9:active {
  border-color: var(--rk-colors-connectButtonInnerBackground);
}

.ju367vca, .ju367vcb:hover, .ju367vcc:active {
  border-color: var(--rk-colors-connectButtonText);
}

.ju367vcd, .ju367vce:hover, .ju367vcf:active {
  border-color: var(--rk-colors-connectButtonTextError);
}

.ju367vcg, .ju367vch:hover, .ju367vci:active {
  border-color: var(--rk-colors-connectionIndicator);
}

.ju367vcj, .ju367vck:hover, .ju367vcl:active {
  border-color: var(--rk-colors-error);
}

.ju367vcm, .ju367vcn:hover, .ju367vco:active {
  border-color: var(--rk-colors-generalBorder);
}

.ju367vcp, .ju367vcq:hover, .ju367vcr:active {
  border-color: var(--rk-colors-generalBorderDim);
}

.ju367vcs, .ju367vct:hover, .ju367vcu:active {
  border-color: var(--rk-colors-menuItemBackground);
}

.ju367vcv, .ju367vcw:hover, .ju367vcx:active {
  border-color: var(--rk-colors-modalBackdrop);
}

.ju367vcy, .ju367vcz:hover, .ju367vd0:active {
  border-color: var(--rk-colors-modalBackground);
}

.ju367vd1, .ju367vd2:hover, .ju367vd3:active {
  border-color: var(--rk-colors-modalBorder);
}

.ju367vd4, .ju367vd5:hover, .ju367vd6:active {
  border-color: var(--rk-colors-modalText);
}

.ju367vd7, .ju367vd8:hover, .ju367vd9:active {
  border-color: var(--rk-colors-modalTextDim);
}

.ju367vda, .ju367vdb:hover, .ju367vdc:active {
  border-color: var(--rk-colors-modalTextSecondary);
}

.ju367vdd, .ju367vde:hover, .ju367vdf:active {
  border-color: var(--rk-colors-profileAction);
}

.ju367vdg, .ju367vdh:hover, .ju367vdi:active {
  border-color: var(--rk-colors-profileActionHover);
}

.ju367vdj, .ju367vdk:hover, .ju367vdl:active {
  border-color: var(--rk-colors-profileForeground);
}

.ju367vdm, .ju367vdn:hover, .ju367vdo:active {
  border-color: var(--rk-colors-selectedOptionBorder);
}

.ju367vdp, .ju367vdq:hover, .ju367vdr:active {
  border-color: var(--rk-colors-standby);
}

.ju367vds, .ju367vdt:hover, .ju367vdu:active {
  box-shadow: var(--rk-shadows-connectButton);
}

.ju367vdv, .ju367vdw:hover, .ju367vdx:active {
  box-shadow: var(--rk-shadows-dialog);
}

.ju367vdy, .ju367vdz:hover, .ju367ve0:active {
  box-shadow: var(--rk-shadows-profileDetailsAction);
}

.ju367ve1, .ju367ve2:hover, .ju367ve3:active {
  box-shadow: var(--rk-shadows-selectedOption);
}

.ju367ve4, .ju367ve5:hover, .ju367ve6:active {
  box-shadow: var(--rk-shadows-selectedWallet);
}

.ju367ve7, .ju367ve8:hover, .ju367ve9:active {
  box-shadow: var(--rk-shadows-walletLogo);
}

.ju367vea, .ju367veb:hover, .ju367vec:active {
  color: var(--rk-colors-accentColor);
}

.ju367ved, .ju367vee:hover, .ju367vef:active {
  color: var(--rk-colors-accentColorForeground);
}

.ju367veg, .ju367veh:hover, .ju367vei:active {
  color: var(--rk-colors-actionButtonBorder);
}

.ju367vej, .ju367vek:hover, .ju367vel:active {
  color: var(--rk-colors-actionButtonBorderMobile);
}

.ju367vem, .ju367ven:hover, .ju367veo:active {
  color: var(--rk-colors-actionButtonSecondaryBackground);
}

.ju367vep, .ju367veq:hover, .ju367ver:active {
  color: var(--rk-colors-closeButton);
}

.ju367ves, .ju367vet:hover, .ju367veu:active {
  color: var(--rk-colors-closeButtonBackground);
}

.ju367vev, .ju367vew:hover, .ju367vex:active {
  color: var(--rk-colors-connectButtonBackground);
}

.ju367vey, .ju367vez:hover, .ju367vf0:active {
  color: var(--rk-colors-connectButtonBackgroundError);
}

.ju367vf1, .ju367vf2:hover, .ju367vf3:active {
  color: var(--rk-colors-connectButtonInnerBackground);
}

.ju367vf4, .ju367vf5:hover, .ju367vf6:active {
  color: var(--rk-colors-connectButtonText);
}

.ju367vf7, .ju367vf8:hover, .ju367vf9:active {
  color: var(--rk-colors-connectButtonTextError);
}

.ju367vfa, .ju367vfb:hover, .ju367vfc:active {
  color: var(--rk-colors-connectionIndicator);
}

.ju367vfd, .ju367vfe:hover, .ju367vff:active {
  color: var(--rk-colors-error);
}

.ju367vfg, .ju367vfh:hover, .ju367vfi:active {
  color: var(--rk-colors-generalBorder);
}

.ju367vfj, .ju367vfk:hover, .ju367vfl:active {
  color: var(--rk-colors-generalBorderDim);
}

.ju367vfm, .ju367vfn:hover, .ju367vfo:active {
  color: var(--rk-colors-menuItemBackground);
}

.ju367vfp, .ju367vfq:hover, .ju367vfr:active {
  color: var(--rk-colors-modalBackdrop);
}

.ju367vfs, .ju367vft:hover, .ju367vfu:active {
  color: var(--rk-colors-modalBackground);
}

.ju367vfv, .ju367vfw:hover, .ju367vfx:active {
  color: var(--rk-colors-modalBorder);
}

.ju367vfy, .ju367vfz:hover, .ju367vg0:active {
  color: var(--rk-colors-modalText);
}

.ju367vg1, .ju367vg2:hover, .ju367vg3:active {
  color: var(--rk-colors-modalTextDim);
}

.ju367vg4, .ju367vg5:hover, .ju367vg6:active {
  color: var(--rk-colors-modalTextSecondary);
}

.ju367vg7, .ju367vg8:hover, .ju367vg9:active {
  color: var(--rk-colors-profileAction);
}

.ju367vga, .ju367vgb:hover, .ju367vgc:active {
  color: var(--rk-colors-profileActionHover);
}

.ju367vgd, .ju367vge:hover, .ju367vgf:active {
  color: var(--rk-colors-profileForeground);
}

.ju367vgg, .ju367vgh:hover, .ju367vgi:active {
  color: var(--rk-colors-selectedOptionBorder);
}

.ju367vgj, .ju367vgk:hover, .ju367vgl:active {
  color: var(--rk-colors-standby);
}

@media screen and (min-width: 768px) {
  .ju367vg {
    align-items: flex-start;
  }

  .ju367vi {
    align-items: flex-end;
  }

  .ju367vk {
    align-items: center;
  }

  .ju367vm {
    display: none;
  }

  .ju367vo {
    display: block;
  }

  .ju367vq {
    display: flex;
  }
}

@keyframes _1luule41 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

._1luule42 {
  animation: _1luule41 3s linear infinite;
}

._1luule43 {
  height: 21px;
  width: 21px;
  background: conic-gradient(from 180deg, #4892fe00 0deg, currentColor 282.04deg, #4892fe00 319.86deg 360deg);
}

@keyframes _9pm4ki0 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0deg);
  }
}

@keyframes _9pm4ki1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

._9pm4ki3 {
  z-index: 2147483646;
  padding: 200px;
  animation: _9pm4ki1 .15s;
  inset: -200px;
  transform: translateZ(0);
}

._9pm4ki5 {
  max-width: 100vw;
  animation: _9pm4ki0 .35s cubic-bezier(.15, 1.15, .6, 1), _9pm4ki1 .15s;
}

._1ckjpok1 {
  box-sizing: content-box;
  max-width: 100vw;
  width: 360px;
}

._1ckjpok2 {
  width: 100vw;
}

._1ckjpok3 {
  width: 768px;
}

._1ckjpok5 {
  box-sizing: border-box;
  width: 100vw;
  border-width: 0;
}

@media screen and (min-width: 768px) {
  ._1ckjpok1 {
    width: 360px;
  }

  ._1ckjpok2 {
    width: 480px;
  }

  ._1ckjpok3 {
    width: 720px;
  }
}

@media screen and (max-width: 767px) {
  ._1ckjpok6 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: -200px;
    padding-bottom: 200px;
    top: 200px;
  }
}

.v9horb3:hover {
  background: unset;
}

.g5kl0l1 {
  border-color: #0000;
}

._1vwt0cg0 {
  background: #fff;
}

._1vwt0cg2 {
  max-height: 464px;
  overflow-y: auto;
}

._1vwt0cg3 {
  min-width: 246px;
}

@media screen and (min-width: 768px) {
  ._1vwt0cg3 {
    min-width: 287px;
  }
}

._1am14410 {
  scrollbar-width: none;
  overflow: auto;
  transform: translateZ(0);
}

._1am14410::-webkit-scrollbar {
  display: none;
}





/*# sourceMappingURL=index.a1ffc73d.css.map */
