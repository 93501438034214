@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Franxfurter';
    src: url('./Franxfurter.woff')
}
  
@font-face {
    font-family: 'Watasuki Tech Sans';
    src: url('./WatatsukiTechSans.woff')
}

body {
    font-family: 'Watasuki Tech Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    background-color: #5AA1E9;
    color: #FFF;   
}

h1, h2, h3, h4, h5, button.rounded-full, a.rounded-full {
    font-family: 'Franxfurter', sans-serif;
}